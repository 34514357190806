@import "../../style/mixins";

.appointment-header {
  @include dashboard-header;
}

.appointment-header-title {
  @include dashboard-header-title;
}

.appointment-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

@mixin patient-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

@mixin appointment-button($color) {
  @include primary-panel-button($color, #FFFFFF);
  font-size: 1rem;
  margin-right: 10px;
  margin-bottom: 10px
}

.appointment-empty-box {
  @include shadow-box(100%);
  margin-bottom: 30px;
}

.appointment-search-box {
  @include shadow-box(100%);
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.appointment-search-input-group {
  position: relative;
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.appointment-search-input-wrapper {
  width: 100%;
  padding-right: 40px;
}

.appointment-search-input-label {
  font-size: 1rem;
  color: #5C5981;
}

.appointment-search-input {
  padding: 0 0;
  width: 100%;
  font-size: 1.25rem;
  outline: none;
  border: none;
  color: #5C5981;
  border-bottom: 1px #CE1C81 solid;
}

.appointment-input-clear-button {
  font-size: 1.25rem;
  color: #555CCF;
}

.appointment-search-button-group {
  padding: 4px 4px;
  border: 1px solid #B3B9C2;
  border-radius: 10px;
}

.appointment-search-type-button {
  padding: 10px 14px;
  font-size: 1.25rem;
}

.appointment-search-type-button-selected {
  margin: 0;
  font-size: 1.25rem;
  border-radius: 8px;
  background:#CE1C81;
  color: #FFFFFF;
}

/*************************************
Appointment Box
*************************************/
.appointment-box {
  @include shadow-box(48.5%);
  margin-bottom: 15px;
}

.appointment-box-header {
  @include patient-info;
}

.appointment-participant, .appointment-time {
  @include title-text(#5C5981);
}

.participant-contact-wrapper {
  @include patient-info;
}

.participant-contact-type {
  @include primary-text(#2033A7);
  width: 30%;
}

.participant-info {
  width: 70%;
  text-align: right;
}

.appointment-buttons-wrapper {
  position: relative;
  margin-top: 20px;
}

.appointment-start-button {
  @include appointment-button(#204CEC);
}

.appointment-reminder-button {
  @include appointment-button(#6720EC);
}

.appointment-cancel-button {
  @include appointment-button(#CE1C81);
}

/*************************************
Appointment Box
*************************************/
.appointment-box {
  @include shadow-box(100%);
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.appointment-box-left {
  width: 50%;
  padding-right: 3%;
  border-right: 1px solid #5C5981;
}

.appointment-box-right {
  width: 50%;
  padding-left: 3%;
}

.appointment-type {
  @include primary-text(#204CEC);
  font-weight: 600;
  margin-bottom: 20px;
}

.appointment-box-right-title {
  @include primary-text(#5C5981);
  font-weight: 600;
  margin-bottom: 10px;
}

.appointment-participant-wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid #a7a6b5;
  border-radius: 15px;
  padding: 5px 15px;
  margin-bottom: 10px;
}

.appointment-participant-text {
  @include primary-text(#204CEC);
}

.remove-participant-button {
  font-size: 1.025rem;
  color: #CE1C81;
}

.appointment-add-participant {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.appointment-add-participant-input {
  width: 80%;
  padding: 0 0;
  font-size: 1.25rem;
  outline: none;
  border: none;
  color: #5C5981;
  border-bottom: 1px #204CEC solid;
}

.appointment-add-participant-button {
  @include secondary-panel-button(#204CEC);
  margin: 0;
}

.appointment-waiting-room {
  display: flex;
}

.appointment-waiting-room-names {
  @include primary-text(#204CEC);
  margin-right: 20px;
}
