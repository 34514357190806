/*************************************
Sidebar
*************************************/
.sidebar {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 18%;
  height: 100%;
  padding: 30px 1.25%;
  background-color: #FFFFFF;
  box-shadow: 0 4px 6px 3px #B3B9C2;
}

.sidebar-header-wrapper,
.sidebar-main-button-wrapper,
.sidebar-secondary-button-wrapper {
  width: 100%;
}

.sidebar-title {
  margin-bottom: 30px;
  text-align: center;
  font-size: 1.375rem;
  font-weight: 600;
  color: #5C5981;
}

/*************************************
ProviderHeader
*************************************/
.provider-header {
  position: relative;
  width: 100%;
  height: 90px;
  display: flex;
  padding: 6%;
  border-radius: 15px;
  border: 1px solid #AAB3C7;
}

.provider-header-left {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #626BE2;
}

.provider-header-left-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #FFFFFF;
}

.provider-header-right {
  width: 67%;
  height: 100%;
}

.provider-header-right-text {
  padding-left: 10px;
}

/*************************************
SidebarMainButton
*************************************/
.sidebar-main-button {
  display: block;
  width: 100%;
  padding: 15px 6%;
  font-size: 1.125rem;
  text-align: left;
  border-radius: 15px;
  background: none;
  color: #5C5981;
}

.sidebar-main-button-active {
  color: #FFFFFF;
  background: linear-gradient(90deg, #204CEC 0%, #5BA0D9 100%);
}

.sidebar-main-button:last-of-type {
  margin-bottom: 0;
}

/*************************************
SidebarSecondaryButton
*************************************/
.sidebar-secondary-button {
  display: inline-block;
  padding: 4% 10%;
  margin-bottom: 12px;
  border-radius: 20px;
  border: 1px solid #204CEC;
  color: #204CEC;
}

.sidebar-secondary-button-active {
  color: #FFFFFF;
  background-color: #204CEC;
}

.sidebar-secondary-button:last-of-type {
  margin-bottom: 0;
}
