@import "../../style/mixins";

.overview-header {
  @include dashboard-header;
}

.overview-header-title {
  @include dashboard-header-title;
}

.overview-wrapper {
  @include shadow-box(100%);
  margin-bottom: 30px;
}

.overview-text {
  @include title-text(#5C5981);
}
