@import "../../style/mixins";

.invite-patient-container {
  @include main-contianer(100%);
}

.invite-patient-title {
  @include header-title;
  margin-bottom: 15px;
}

.invite-patient-back-btn {
  @include tertiary-panel-button;
  display: inline-block;
  margin-bottom: 30px;
}

.invite-patient-input-wrapper {
  @include shadow-box(100%);
  margin-bottom: 30px;
}

.invite-patient-input {
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.75rem;
  outline: none;
  color: #5C5981;
  border: none;
  border-bottom: 3px solid #EDEFF7;
}

.invite-patient-btn-group {
  display: flex;
  justify-content: center;
}

.invite-patient-btn:first-of-type {
  @include primary-panel-button(#6720EC, #FFFFFF);
  border-radius: 10px;
}

.invite-patient-btn:nth-of-type(2) {
  @include primary-panel-button(#204CEC, #FFFFFF);
  border-radius: 10px;
  margin: 0;
}
