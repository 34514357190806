@import "../../style/mixins";

.patients-header {
  @include dashboard-header;
}

.patients-header-title {
  @include dashboard-header-title;
}

/*************************************
PatientBox
*************************************/
.patient-wrapper {
  @include shadow-box(100%);
  margin-bottom: 15px;
}

.patient-info-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.patient-info-header {
  display: flex;
  width: 75%;
}

.patient-name {
  @include title-text(#5C5981);
  margin-right: 3%;
}

.patient-info-btn {
  @include tertiary-panel-button();
}

.patient-action-btn-wrapper {
  width: 25%;
  padding-left: 3%;
  border-left: 1px solid #808080;
}

.schedule-appointment-btn {
  display: block;
  width: 100%;
  border-radius: 10px;
  font-size: 1.25rem;
  color: #204CEC;
}

/*************************************
PatientInfo
*************************************/
.patient-info-container {
  max-width: 500px;
  margin-top: 15px;
}

.patient-contact-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.patient-contact-wrapper:last-of-type {
  margin-bottom: 0;
}

.patient-contact-type {
  @include primary-text(#2033A7);
  width: 30%;
}

.patient-info-text {
  @include primary-text(#5C5981);
  width: 70%;
}

/*************************************
PatientSearch
*************************************/
.patient-search-bar {
  @include shadow-box(100%);
  margin-bottom: 15px;
}

.patient-search-input {
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.75rem;
  outline: none;
  color: #5C5981;
  border: none;
  border-bottom: 3px solid #EDEFF7;
}
