@import "../../style/mixins";

.message-info-wrapper {
  @include shadow-box(100%);
  margin-bottom: 15px;
}

.message-content-container {
  display: flex;
  justify-content: space-between;
}

.message-request-left {
  width: 75%;
}

.message-sender-wrapper,
.message-content {
  margin-bottom: 10px;
}

.message-sender-wrapper {
  display: flex;
  align-items: center;
}

.message-sender {
  @include title-text(#5C5981);
  margin-right: 20px;
}

.patient-info-button {
  @include tertiary-panel-button();
}

.message-content-wrapper {
  min-height: 40px;
}

.message-content {
  @include primary-text(#5C5981);
}

.last-message-content {
  margin-bottom: 0;
}

.message-content-highlight {
  color: #204CEC;
}

.message-button-wrapper {
  display: flex;
}

.message-button {
  @include secondary-panel-button(#5976E1);
}

.message-request-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  padding-left: 20px;
  border-left: 1px solid #808080;
}

.message-time {
  @include primary-text(#5C5981);
}

.message-read-button {
  @include primary-panel-button(#204CEC, #FFFFFF);
}