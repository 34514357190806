@import "../../style/mixins";

.status {
  @include main-contianer(100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status-title {
  @include header-title;
  margin: 0;
  margin-bottom: 15px;
}

.status-message {
  @include primary-text(#5C5981);
  margin-bottom: 15px;
}

.invite-patient-btn-group {
  display: flex;
  justify-content: center;
}

.status-btn:first-of-type {
  @include primary-panel-button(#6720EC, #FFFFFF);
  border-radius: 10px;
}

.status-btn:nth-of-type(2) {
  @include primary-panel-button(#204CEC, #FFFFFF);
  border-radius: 10px;
  margin: 0;
}
