@import "../../style/mixins";

/*************************************
GateForEntry
*************************************/
.entry {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.entry-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  min-height: 300px;
  padding: 30px 3%;
  border-radius: 15px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 6px 3px #B3B9C2;
}

.entry-title {
  margin-bottom: 30px;
  font-size: 1.75rem;
  font-weight: 600;
  color: #626BE2;
}

.entry-input-label {
  margin-bottom: 15px;
  font-size: 1.5rem;
  text-align: center;
  line-height: 26px;
  color: #5C5981;
}

.entry-input-field {
  width: 70%;
  height: 60px;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.5rem;
  border-radius: 15px;
  border: 1px solid #5C5981;
}

.entry-button {
  padding: 10px 30px;
  margin-bottom: 15px;
  font-size: 1.5rem;
  border-radius: 30px;
  background-color: #204CEC;
  color: #FFFFFF;
}

.entry-email-button {
  @include tertiary-panel-button;
}
