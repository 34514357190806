@import "../../style/mixins";

.calendar-container {
  @include main-contianer(100%);
}

.calendar-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.calendar-header-title {
  @include header-title;
  margin-right: 9px;
}

.calendar-header-btn {
  @include header-title;
  border-bottom: 3px solid #999DCE;
}

.calendar-wrapper {
  @include shadow-box(100%);
  display: flex;
}

.service-type-wrapper {
  width: 18%;
  border-right: 2px solid #E7E7EF;
  padding-right: 2%;
}

.service-type-header-title {
  @include title-text(#5C5981);
  margin-bottom: 15px;
}

.service-type-btn {
  @include primary-panel-button(#FFFFFF, #5C5981);
  border: 1px solid #5C5981;
}

.service-type-btn-active {
  @include primary-panel-button(#204CEC, #FFFFFF);
}

.service-type-btn, .service-type-btn-active {
  display: block;
  margin: 0;
  margin-bottom: 15px;
  border-radius: 10px;
  font-size: 1rem;
}

.calendar-back-btn {
  @include tertiary-panel-button;
  display: inline-block;
  margin-bottom: 30px;
}

.date-wrapper {
  width: 32%;
  padding: 0 2%;
  border-right: 2px solid #E7E7EF;
}

.date-header-title {
  @include title-text(#5C5981);
  margin-bottom: 15px;
}

.date-subheader-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.date-subheader-title {
  @include primary-text(#5C5981);
}

.date-subheader-btn-wrapper {
  padding: 5px 10px;
  border: 1px solid #5C5981;
  border-radius: 7px;
}

.date-subheader-btn {
  padding: 0 10px;
}

.date-subheader-btn:first-of-type {
  border-right: solid 1px #AAB3C7;
}

.days-of-week-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.days-of-week {
  display: inline-block;
  width: 31px;
}

.date-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.date-btn-placeholder, .date-btn {
  display: inline-block;
  vertical-align: top;
  width: 31px;
  height: 31px;
  border-radius: 16px;
  color: #5C5981;
}

.date-btn-active {
  color: #FFFFFF;
  background: #204CEC;
}

.date-btn-placeholder {
  background: none;
}

.time-wrapper {
  width: 34%;
  padding: 0 2%;
  border-right: 2px solid #E7E7EF;
}

.time-header-title {
  @include title-text(#5C5981);
  margin-bottom: 15px;
}

.part-of-day {
  width: 100%;
}

.part-of-day-header {
  @include primary-text(#204CEC);
  margin-bottom: 15px;
}

.time-morning, .time-afternoon {
  display: flex;
  flex-wrap: wrap;
}

.time-morning {
  // background: grey;
}

.time-afternoon {
  // background: lightgrey;
}

.select-time-btn {
  @include primary-panel-button(#FFFFFF, #5C5981);
  border: 1px solid #5C5981;
}

.select-time-btn-active {
  @include primary-panel-button(#204CEC, #FFFFFF);
}

.select-time-btn, .select-time-btn-active {
  margin: 0;
  margin-bottom: 15px;
  margin-right: 3%;
  border-radius: 10px;
  font-size: 1rem;
}

.create-appointment-wrapper {
  display: flex;
  flex-direction: column;
  width: 16%;
  padding-left: 2%;
}

.create-appointment-header-title {
  @include title-text(#5C5981);
  margin-bottom: 15px;
}

.create-appointment-type, .create-appointment-time {
  @include primary-text(#204CEC);
  margin-bottom: 10px;
}

.create-appointment-time:last-of-type {
  margin-bottom: 15px;
}

.create-appointment-btn {
  @include primary-panel-button(#6720EC, #FFFFFF);
  display: block;
  margin: 0;
  margin-bottom: 15px;
  border-radius: 10px;
}

.back-to-dashboard-btn {
  @include tertiary-panel-button;
  align-self: center;
}
