@import "../../style/mixins";

.dashboard-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.dashboard-panel {
  @include dashboard-panel;
}
